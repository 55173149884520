// CAROUSEL DEFAULT STYLES
.slick-slider
{
    position: relative;

    display: block;

    -moz-box-sizing: border-box;
         box-sizing: border-box;

    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list
{
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus
{
    outline: none;
}
.slick-list.dragging
{
    cursor: pointer;
    cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list
{
    -webkit-transform: translate3d(0, 0, 0);
       -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
         -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.slick-track
{
    position: relative;
    top: 0;
    left: 0;

    display: block;
}
.slick-track:before,
.slick-track:after
{
    display: table;

    content: '';
}
.slick-track:after
{
    clear: both;
}
.slick-loading .slick-track
{
    visibility: hidden;
}

.slick-slide
{
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide
{
    float: right;
}
.slick-slide img
{
    display: block;
}
.slick-slide.slick-loading img
{
    display: none;
}
.slick-slide.dragging img
{
    pointer-events: none;
}
.slick-initialized .slick-slide
{
    display: block;
}
.slick-loading .slick-slide
{
    visibility: hidden;
}
.slick-vertical .slick-slide
{
    display: block;

    height: auto;

    border: 1px solid transparent;
}

// OUR STYLES
.c-hero {
  max-width: 1102px;
  margin: 0 auto 1em;
}
.c-hero__slide {
  position: relative;
}
.c-hero__img {
	display: block;
}
.c-hero__link {
  font-weight: 700;
  padding: 0.3em 1.4em;
  display: inline-block;
  border: 2px solid white;
  &:link, &:visited { color: white; text-decoration: none; }
  &:hover, &:active, &:focus { color: white; text-decoration: none; }
}
.c-hero__textarea-wrap {
  top: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  &.left {
    left: 0;
    background: linear-gradient(to right, rgba(0,0,0,0.4) 0%, rgba(0,0,0,0) 100%);
  }
  &.right {
    right: 0;
    background: linear-gradient(to right, rgba(0,0,0,0) 0%, rgba(0,0,0,0.4) 100%);
  }
  &.center {
    left: 50%;
    margin-left: -15%;
    background: none;
  }
}
.c-hero__textarea {
  width: 100%;
  bottom: 30%;
  color: white;
  padding-left: 4em;
  padding-right: 4em;
  text-align: center;
  position: absolute;
}
.c-hero__text {
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 0.8em;
  font-family: "ff-duper-web-pro", sans-serif;
}
.js .c-hero__slide {
  display: none;
}
.slick-initialized .c-hero__slide {
  display: block;
}

@media (min-width: 780px) {

  .c-hero__text {
    font-size: 2.25em;
  }

  .c-hero__textarea-wrap {
    display: block;
    width: 50%;
  }

}

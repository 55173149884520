.c-page-header {
  padding-top: 0.5em;
  text-align: center;
}
.c-page-header__lead {
  margin: 0 0 1em;
  font-size: 1.4em;
  font-weight: 400;
  line-height: 1.2;
  padding: 0em 1em 1em;
  font-family: "ff-duper-web-pro", sans-serif;
}
.c-page-header__heading {
  font-size: 2em;
  margin: 0.5em 0;
  font-weight: 700;
  font-family: "ff-duper-web-pro", sans-serif;
}

@media (min-width: 780px) {

  .c-page-header__lead {
    padding: 0 10em 0;
  }

}

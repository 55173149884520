.c-home-intro {
	text-align: center;
	padding: 0em 1em 1em;
}
.c-home-intro__lead {
  font-weight: 400;
	font-size: 1.3em;
	line-height: 1.2;
	margin-bottom: 1em;
	font-family: "ff-duper-web-pro", sans-serif;
}
.c-home-intro__text {
  font-weight: 700;
}

@media (min-width: 780px) {

	.c-home-intro {
		text-align: center;
		padding: 1em 13em 2em;
	}
	
}

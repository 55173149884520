.c-page-footer__upper {
  @extend %clearfix;
}
.c-page-footer__lower {
  padding-top: 1em;
  @extend %clearfix;
  padding-bottom: 1em;
  border-top: 0.25em solid $cs-a3;
}
.c-page-footer__wash {
	display: block;
	text-align: center;
	a:link, a:visited { color: $cs-font; text-decoration: none; }
	a:hover, a:active, a:focus { color: $cs-font; text-decoration: underline; }
}
.c-page-footer__twitter {
	color: $cs-a4;
	float: right;
	font-size: 1.7em;
	&:link, &:visited { color: $cs-a4; text-decoration: none; }
	&:hover, &:active, &:focus { 	color: $cs-a4; text-decoration: none; }
}
.c-page-footer__logos {
	margin: 0;
	padding: 0;
	list-style: none;
	text-align: center;
}
.c-page-footer__logo-item {
  margin-right: 0.5em;
  margin-bottom: 0.5em;
  display: inline-block;
}

@media (min-width: 780px) {

	.c-page-footer__logos {
		text-align: left;
		display: inline-block;
	}
	.c-page-footer__wash {
		float: right;
		text-align: left;
		display: inline-block;
	}

}

.s-prose {

  padding-top: 1em;

  blockquote {
    margin-left: 0;
    margin-right: 0;
    padding-left: 1em;
    border-left: 0.2em solid $cs-a1;
  }

  a:link, a:visited { color: $cs-a1; text-decoration: none; }
  a:hover, a:active, a:focus { color: $cs-a1; text-decoration: underline; }

  ul,ol {
    margin-left: 1em;
  }

}

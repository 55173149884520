.c-cat-nav {
  padding-bottom: 2em;
  text-decoration: none;
}
.c-cat-nav__item {
  margin-right: 0.5em;
  display: inline-block;
}

.c-cat-nav__link {
  display: block;
  padding: 0.5em 1em;
  font-size: 0.9em;
  text-transform: uppercase;
  @include transition(all 0.4s);
  border: 0.15em solid $cs-a3;
  &:link, &:visited { text-decoration: none; }
  &:hover, &:active, &:focus {
    text-decoration: none;
      @include transition(all 0.1s);
      border-color: darken($cs-a3, 10%);
  }
}
.c-cat-nav__item:first-child {
  .c-cat-nav__link {
    border-color: $cs-a2;
    background-color: $cs-a2;
    &:link, &:visited { color: white; }
    &:hover, &:active, &:focus {
      color: darken(white, 10%);
      border-color: darken($cs-a2, 10%);
      background-color: darken($cs-a2, 10%);
    }
  }
}
.c-cat-nav__item:nth-child(2n) {
  .c-cat-nav__link {
    border-color: darken($cs-a1, 10%);
    &:link, &:visited { color: $cs-a1; }
    &:hover, &:active, &:focus {
      color: darken($cs-a1, 10%);
      border-color: darken($cs-a1, 10%);
    }
  }
}
.c-cat-nav__item:nth-child(3n) {
  .c-cat-nav__link {
    border-color: darken($cs-a3, 10%);
    &:link, &:visited { color: $cs-a3; }
    &:hover, &:active, &:focus {
      color: darken($cs-a3, 10%);
      border-color: darken($cs-a3, 10%);
    }
  }
}
.c-cat-nav__item:nth-child(5n) {
  .c-cat-nav__link {
    border-color: darken($cs-a4, 10%);
    &:link, &:visited { color: $cs-a4; }
    &:hover, &:active, &:focus {
      color: darken($cs-a4, 10%);
      border-color: darken($cs-a4, 10%);
    }
  }
}

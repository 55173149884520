.c-home-social {
	color: white;
	margin: 0.5em 0;
	font-weight: 400;
	font-size: 1.4em;
	text-align: center;
	padding: 0.3em 0 0.5em;
	background-color: $cs-a3;
	font-family: "ff-duper-web-pro", sans-serif;
}
.c-home-social__icon {
	color: $cs-a4;
	bottom: -0.12em;
	font-size: 1.2em;
	position: relative;
	padding-left: 0.15em;
	padding-right: 0.15em;
}
.c-home-social__text {
	margin: 0;
}
.c-home-social__link {
	color: white;
	display: block;
	margin-top: 0.5em;
	transition: all 0.3s;
	text-decoration: none;
	&:link, &:visited { color: white; text-decoration: none; }
	&:hover, &:active, &:focus { color: $cs-a1; text-decoration: none; }
}
.c-home-social__seperator {
	display: none;
}

@media (min-width: 780px) {

	.c-home-social__seperator {
		display: inline;
	}

	.c-home-social__link {
		margin-top: 0;
		display: inline;
	}

}

// NAVIGATION
.c-nav {
  @extend %clearfix;
}
.c-nav__list {
  margin: 0;
  padding: 0;
  clear: right;
  display: none; // on mobile
  list-style: none;
  border-top: 1px solid darken($cs-a2, 15%);
}
.c-nav__item {
  border-bottom: 1px solid darken($cs-a2, 15%);
}
.c-nav__link {
  padding: 0.5em;
  display: block;
  color: $cs-font;
  font-size: 1.2em;
  transition: all 0.5s;
  text-decoration: none;
  background-color: $cs-a2;
  &:hover, &:active, &:focus {
    transition: all 0.3s;
    color: $cs-font;
    background-color: darken($cs-a2, 10%);
  }
  &.is-active {
    font-weight: 700;
  }
}
.c-nav__btn {
  float: right;
  padding: 1em;
  color: $cs-font;
  font-size: 0.8em;
  text-decoration: none;
  &:link, &:visited { color: $cs-font; }
  &:hover, &:active, &:focus { color: $cs-font; }
}

@media screen and (min-width: 769px) {

  .c-nav {
    margin-top: 0.5em;
    text-align: center;
    padding-bottom: 1.5em;
  }
  .c-nav__list {
    margin: 0;
    padding: 0;
    display: block; // on desktop
    border-top: none;
  }
  .c-nav__item {
    margin-right: 0.8em;
    padding-right: 1.5em;
    border-bottom: none;
    display: inline-block;
    position: relative;
    &:after {
      content: "/";
      top: 0;
      right: 0;
      color: inherit;
      position: absolute;
    }
    &:last-child {
      margin-right: 0;
      padding-right: 0;
      &:after {
        content: "";
      }
    }
  }
  .c-nav__link {
    padding: 0;
    color: $cs-font;
    font-size: 0.9em;
    display: inline-block;
    background-color: transparent;
    &:hover, &:active, &:focus {
      color: $cs-font;
      background-color: transparent;
    }
  }
  .c-nav__btn {
    display: none;
  }

}

html {
	color: $cs-font;
  font-family: "proxima-nova-soft", sans-serif;
}
img {
	height: auto;
	max-width: 100%;
}

@media screen and (min-width: 769px) {

  body {
    animation: fadeIn 2s ease;
  }

}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.c-cta-nav {
  @include display(flex);
  @include flex-wrap(wrap);
  @include justify-content(space-between);
  margin: 0;
  padding: 0;
  list-style: none;
}
.c-cta-nav__item {
  width: 100%;
  margin-bottom: 1em;
  @include transition(all 0.2s);

  &:first-child {
    order: 2;
  }
  &:nth-child(2) {
    order: 1;
  }
  &:nth-child(3) {
    order: 3;
  }
  &:last-child {
    order: 4;
  }
}

.c-cta-nav__link {
  @include display(flex);
  @include flex-direction(column);
  @include justify-content(center);
  height: 160px;
  text-align: center;
  @include transition(all 0.1s);
  text-decoration: none;
  background-color: #EEEEEE;
  &:link, &:visited {
    color: white;
    opacity: 0.9;
    text-decoration: none;
  }
  &:hover, &:active, &:focus {
    opacity: 1;
    color: white;
    @include transition(all 0.3s);
    text-decoration: none;
    .c-cta-nav__heading--img {
      opacity: 0;
      @include transition(all 1s);
    }
  }
}
// color options
.c-cta-nav__link.cs-a1 {
  background-color: $cs-a1;
  @include transition(background-color 1s);
  &:link, &:visited { background-color: $cs-a1; }
  &:hover, &:active, &:focus {
    background-color: $cs-a4;
    @include transition(background-color 1s);
  }
}
.c-cta-nav__link.cs-a4 {
  background-color: $cs-a4;
  @include transition(background-color 1s);
  &:link, &:visited { background-color: $cs-a4; }
  &:hover, &:active, &:focus {
    background-color: $cs-a1;
    @include transition(background-color 1s);
  }
}
.c-cta-nav__link--img {
  @include justify-content(flex-start);
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url('/assets/img/small-img.png');
}
.c-cta-nav__heading {
  padding: 0;
  line-height: 1;
  font-weight: 400;
  font-size: 1.4em;
  position: relative;
  margin: 0 0 0.5em 0;
  font-family: "ff-duper-web-pro", sans-serif;
  &:after {
    left: 50%;
    width: 40%;
    content: "";
    bottom: -0.5em;
    display: block;
    margin-left: -20%;
    position: absolute;
    border-bottom: 0.15em solid white;
  }
}
.c-cta-nav__heading--img {
  padding: 0.5em;
  @include transition(all 1s);
  background-color: #323232;
  background-color: rgba(0,0,0, 0.9);
    &:after {
    border-bottom: none;
  }
}
// color options
.c-cta-nav__heading--img.cs-a5 {
  background-color: $cs-a5;
  background-color: rgba($cs-a5, 0.9);
}
.c-cta-nav__heading--img.cs-a6 {
  background-color: $cs-a6;
  background-color: rgba($cs-a6, 0.9);
}
.c-cta-nav__text {
  margin: 0;
  font-size: 1.1em;
  line-height: 1.2;
  padding: 0.6em 2em;
}

.no-flexbox {
  .c-cta-nav__item {
    width: 23.5%;
    margin-right: 1%;
    margin-bottom: 0;
    vertical-align: top;
    display: inline-block;
  }
}

@media (min-width: 376px) {
  .c-cta-nav__item {
    width: 48%;
    //margin-right: 2%;
  }
  .c-cta-nav__item:nth-of-type(even) {
    margin-right: 0;
  }
}

@media (min-width: 780px) {
  .c-cta-nav__item {
    width: 24.25%;
    //margin-right: 1%;
    margin-bottom: 0;
  }
  .c-cta-nav__item:nth-of-type(even) {
    //margin-right: 1%;
  }
    .c-cta-nav__item:last-child {
    margin-right: 0;
  }
}

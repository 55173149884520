.c-brand {
  margin-top: 0.2em;
  margin-bottom: 0;
  text-align: center;
}
.c-brand__link {
  color: $cs-a1;
  text-decoration: none;
  &:hover, &:active, &:focus {
    color: black;
  }
}
.c-brand__img {
  display: block;
  margin: 0 auto;
}
.c-brand__img--1 {
  margin-bottom: 0.2em;
  @include animation(rocking 2s infinite);
  @include transform-origin(center bottom);
}

@include keyframes(rocking) {
  0% {
    @include transform(rotate(8deg));
  }
  50% {
    @include transform(rotate(-8deg));
  }
  100% {
    @include transform(rotate(8deg));
  }
}

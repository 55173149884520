.c-sec-nav-img{
  margin: 0;
  padding: 0;
  list-style: none;
}
.c-sec-nav-img__item {
  margin-bottom: 0.5em;
}

.c-sec-nav-img__heading {
  top: 0;
  left: 0;
  margin: 0;
  z-index: 10;
  width: 100%;
  position: absolute;
  @include transition(all 1s);
}

.c-sec-nav-img__image {
  position: relative;
}

.c-sec-nav-img__link {
  display: block;
  text-align: center;
  position: relative;
  @include transition(all 0.1s);
  &:link, &:visited {
    color: white;
    opacity: 0.9;
    text-decoration: none;
  }
  &:hover, &:active, &:focus {
    opacity: 1;
    color: white;
    @include transition(all 0.3s);
    text-decoration: none;
    .c-sec-nav-img__heading {
      opacity: 0;
      @include transition(all 1s);
    }
  }
}

.c-testimonial {
  margin-bottom: 2em;
}
.c-testimonial__customer {
  font-weight: 700;
  padding-left: 1em;
  margin-top: 0.4em;
}
.c-testimonial_quote {
  margin-bottom: 0;
}

.c-article-module-listing {
  margin: 0;
  padding: 0;
  list-style: none;
}
.c-article-module {
  width: 100%;
  margin-bottom: 1em;
  display: inline-block;
  @include transition(all 1s);
}
.c-article-module__link {
  display: block;
  @include transition(all 0.6s);
  &:link, &:visited {
    color: $cs-font;
    text-decoration: none;
    background-color: lighten($cs-a3, 15%);
    .c-article-module__more {
      color: $cs-font;
    }
  }
  &:hover, &:active, &:focus {
    color: $cs-font;
    text-decoration: none;
    @include transition(all 0.1s);
    background-color: darken(lighten($cs-a3, 15%), 10%);
    .c-article-module__more {
      color: white;
      @include transition(all 0.1s);
    }
  }
}
.c-article-module__header {
  padding: 1em 1.5em;
  text-align: center;
  background-color: darken($cs-a3, 25%);
  font-family: "ff-duper-web-pro", sans-serif;
}
.c-article-module__heading {
  margin: 0;
  color: white;
  font-size: 1.4em;
}
.c-article-module__date {
  margin: 0;
  color: white;
  font-size: 1.2em;
}
.c-article-module__overview {
  padding: 0.5em 1em;
}
.c-article-module__more {
  font-size: 1.2em;
  font-weight: 700;
  @include transition(all 0.6s);
  font-family: "ff-duper-web-pro", sans-serif;
}

.no-csscolumns {
  .c-article-module {
    width: 33%;
    padding-left: 1%;
    padding-right: 1%;
    vertical-align: top;
  }
}

.c-article-module:nth-child(2n) {
  .c-article-module__header {
    background-color: $cs-a1;
  }
  .c-article-module__link {
    &:link, &:visited {
      background-color: lighten($cs-a1, 50%);
    }
    &:hover, &:active, &:focus {
      background-color: darken(lighten($cs-a1, 50%), 10%);
    }
  }
}
.c-article-module:nth-child(3n) {
  .c-article-module__header {
    background-color: darken($cs-a2, 30%);
  }
  .c-article-module__link {
    &:link, &:visited {
      background-color: $cs-a2;
    }
    &:hover, &:active, &:focus {
      background-color: darken($cs-a2, 10%);
    }
  }
}
.c-article-module:nth-child(5n) {
  .c-article-module__header {
    background-color: $cs-a4;
  }
  .c-article-module__link {
    &:link, &:visited {
      background-color: lighten($cs-a4, 40%);
    }
    &:hover, &:active, &:focus {
      background-color: darken(lighten($cs-a4, 40%), 10%);
    }
  }
}

@media screen and (min-width: 568px) {

  .c-article-module-listing {
    @include column-count(2);
    @include column-gap(1em);
  }

}

@media screen and (min-width: 769px) {

  .c-article-module-listing {
    @include column-count(3);
  }

}

.c-alert {
  padding: 1em;
  border-radius: 0.2em;
  background-color: #F5F5F5;
  color: darken(#F5F5F5, 30%);
  border: 0.1em solid darken(#F5F5F5, 10%);
}

.c-alert--warning {
  background-color: #FFE0B2;
  color: darken(#FFE0B2, 30%);
  border: 0.1em solid darken(#FFE0B2, 10%);
}

.c-alert--danger {
  color: white;
  background-color: $cs-a1;
  border: 0.1em solid darken($cs-a1, 10%);
}

.o-wrapper {
  @extend %clearfix;
  max-width: 1102px;
  margin-right: auto;
  margin-left:  auto;
  padding-left: 0.3em;
  padding-right: 0.3em;
}

.lt-ie9 {
  .o-wrapper {
    width: 1102px;
    max-width: none;
  }
}

.c-sec-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}
.c-sec-nav__item {
  width: 100%;
  margin-bottom: 0.5em;
}

.c-sec-nav__link {
  display: block;
  padding: 0.7em;
  font-size: 1.4em;
  font-weight: 400;
  text-align: center;
  @include transition(all 0.2s);
  font-family: "ff-duper-web-pro", sans-serif;
  &:link, &:visited {
    color: white;
    text-decoration: none;
    background-color: $cs-a1;
  }
  &:hover, &:active, &:focus {
    color: white;
    text-decoration: none;
      background-color: darken($cs-a1, 10%);
  }
}

.c-sec-nav__item:first-child .c-sec-nav__link {
    &:link, &:visited {
    background-color: darken($cs-a2, 10%);
  }
  &:hover, &:active, &:focus {
    background-color: darken($cs-a2, 20%);
  }
}
.c-sec-nav__item:nth-of-type(2n) .c-sec-nav__link {
    &:link, &:visited {
    background-color: $cs-a4;
  }
  &:hover, &:active, &:focus {
    background-color: darken($cs-a4, 10%);
  }
}

@media screen and (min-width: 568px) {

  .c-sec-nav-img__item {
    @include span-columns(6);
  }

}

@media screen and (min-width: 769px) {

  .c-sec-nav-img__item {
    @include span-columns(12);
  }

}

.c-gallery {
  padding-left: 2%;
  padding-right: 2%;
  @extend %clearfix;
}
.c-gallery__item {
  float: left;
  display: block;
  margin-bottom: 1em;
}

@media (min-width: 480px) {

  .c-gallery {
    padding-left: 3%;
    padding-right: 0;
  }

  .c-gallery__item {
    width: 47%;
    margin-right: 3%;
    vertical-align: top;
    display: inline-block;
  }

}

@media (min-width: 480px) {

  .c-gallery {
    padding-left: 3%;
    padding-right: 0;
  }

  .c-gallery__item {
    width: 30%;
    margin-right: 3%;
  }

}

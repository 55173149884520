/*------------------------------------*\
    #MAIN
\*------------------------------------*/

/**
 * CONTENTS
 *
 * SETTINGS
 * Colors...............Manage our color palette in isolation.
 *
 * GENERIC
 * Normalize.css........A level playing field.
 * Box-sizing...........Better default `box-sizing`.
 * Reset................A pared back reset to remove margins.
 * Lightbox.............Style use for the lightbox plugin.
 *
 * ELEMENTS
 * Page.................Page-level styles (HTML element).
 *
 * OBJECTS
 * Wrappers.............Wrappers and page constraints.
 * Layout...............Generic layout module.
 *
 * COMPONENTS
 * Hero................ Main banner
 * Brand................ Site logo
 * Aside
 * Alert ................ Alert box
 * Cat Nav ................ Category navigation
 * CTA Nav ................ Call to action navigation buttons
 * Gallery
 * Sec Nav
 * Nav Toggle
 * Sec Nav Img ................ Aside page links
 * Home Intro
 * Home Social
 * Home Page Header
 * Primary Nav
 * Page Footer
 * Page Header
 * Testimonial
 * Article Module
 *
 * SCOPES
 * Prose................Set up a new styling context for long-format text.
 *
 * TRUMPS
 * Widths...............Simple width helper classes.
 */

 @font-face {
 	font-family: 'icomoon';
 	src:url('../fonts/icomoon.eot?e9sclz');
 	src:url('../fonts/icomoon.eot?e9sclz#iefix') format('embedded-opentype'),
 		url('../fonts/icomoon.ttf?e9sclz') format('truetype'),
 		url('../fonts/icomoon.woff?e9sclz') format('woff'),
 		url('../fonts/icomoon.svg?e9sclz#icomoon') format('svg');
 	font-weight: normal;
 	font-style: normal;
 }

 [class^="icon-"], [class*=" icon-"] {
 	font-family: 'icomoon';
 	speak: none;
 	font-style: normal;
 	font-weight: normal;
 	font-variant: normal;
 	text-transform: none;
 	line-height: 1;

 	/* Better Font Rendering =========== */
 	-webkit-font-smoothing: antialiased;
 	-moz-osx-font-smoothing: grayscale;
 }

 .icon-twitter:before {
 	content: "\e902";
 }

@import "../bower_components/bourbon/app/assets/stylesheets/bourbon";
@import "../bower_components/neat/app/assets/stylesheets/neat";

@import "settings.colors";

@import "generic.normalize";
@import "generic.box-sizing";
@import "generic.reset";
@import "generic.lightbox";

@import "elements.page";

@import "objects.wrappers";
@import "objects.layout";

@import "component.hero";
@import "component.brand";
@import "component.aside";
@import "component.alert";
@import "component.cat-nav";
@import "component.cta-nav";
@import "component.gallery";
@import "component.sec-nav";
@import "component.nav-toggle.scss";
@import "component.sec-nav-img";
@import "component.home-intro";
@import "component.home-social";
@import "component.home-page-header";
@import "components.nav-primary";
@import "component.page-footer";
@import "component.page-header";
@import "component.testimonial";
@import "component.article-module";

@import "scope.prose";

@import "trumps.widths";
